import React, { useState } from 'react'
import { Formik } from 'formik'

import Layout from '../../components/Layout'
import { ClassIIIValidation } from '../../components/Forms/Validation'
import { ScaleToolsNumberInput } from '../../components/Forms/ScaleToolsTextInput'
import ScaleToolsSelectComponent from '../../components/Forms/ScaleToolsSelect'

const ClassIIITolerance = () => {
    const [capacity, setCapacity] = useState(0)
    const [scaleMax, setScaleMax] = useState(0)
    const [divisionSize, setDivisionSize] = useState(0)
    const [unitsAbbr, setUnitsAbbr] = useState('')

    const GetUnitAbbreviation = unit => {
        switch (unit) {
            case 'Grams':
                return 'g'
            case 'Ounces':
                return 'oz'
            case 'Pounds':
                return 'lbs'
            case 'Kilograms':
                return 'kg'
            default:
                return ''
        }
    }

    const HandleSubmit = values => {
        const { capacity: formCapacity, division, units } = values
        setCapacity(formCapacity)
        setScaleMax(formCapacity / division)
        setDivisionSize(division)
        setUnitsAbbr(GetUnitAbbreviation(units))
    }

    const FormatNumber = inNumber => {
        const asNumber = Number(inNumber)
        return asNumber.toLocaleString(undefined, { maximumFractionDigits: 3 })
    }

    const GetTableRow = (min, max, tolerance) => {
        if (scaleMax === 0 || scaleMax < min) {
            return null
        }

        const rangeMin = FormatNumber(divisionSize * min)
        const rangeMax = FormatNumber(Math.min(divisionSize * max, capacity))

        const maintenanceTolerance = FormatNumber(tolerance * divisionSize)
        const acceptanceTolerance = FormatNumber(maintenanceTolerance / 2)

        const formattedMin = FormatNumber(min)
        const formattedMax = FormatNumber(Math.min(max, scaleMax))

        return (
            <tr>
                <td data-th="Division Range">
                    {formattedMin} to {formattedMax}
                </td>
                <td data-th="Range of Test Weights">
                    Min: {rangeMin}, Max: {rangeMax}
                    {unitsAbbr}
                </td>
                <td data-th="Maintenance Tolerance">
                    {maintenanceTolerance}
                    {unitsAbbr}
                </td>
                <td data-th="Acceptance Tolerance">
                    {acceptanceTolerance}
                    {unitsAbbr}
                </td>
            </tr>
        )
    }

    return (
        <Layout>
            <div id="page-main" className="section-wrapper page-content">
                <h1>Class III Tolerances</h1>
                <p>
                    Calculate the allowable maintenance and acceptance tolerances for Legal for
                    Trade applications.
                </p>
                <Formik
                    onSubmit={HandleSubmit}
                    initialValues={{
                        capacity: 0,
                        division: 1,
                        units: 'Grams'
                    }}
                    validationSchema={ClassIIIValidation}
                >
                    {({ handleSubmit, resetForm }) => {
                        const ResetForm = () => {
                            resetForm()
                            setScaleMax(0)
                            setUnitsAbbr('')
                        }

                        return (
                            <form id="tolerances" onSubmit={handleSubmit}>
                                <div className="col-section col-no-margin">
                                    <div className="col-xs-12 col-sm-6">
                                        <ScaleToolsNumberInput label="Capacity:" name="capacity" />
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <ScaleToolsSelectComponent
                                            name="division"
                                            label="Division Size:"
                                            options={[
                                                '0.0001',
                                                '0.0002',
                                                '0.0005',
                                                '0.001',
                                                '0.002',
                                                '0.005',
                                                '0.01',
                                                '0.02',
                                                '0.05',
                                                '0.1',
                                                '0.2',
                                                '0.5',
                                                '1',
                                                '2',
                                                '5',
                                                '10',
                                                '20',
                                                '50',
                                                '100',
                                                '200',
                                                '500'
                                            ]}
                                        />
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <ScaleToolsSelectComponent
                                            name="units"
                                            label="Unit of Measure:"
                                            options={['Grams', 'Ounces', 'Pounds', 'Kilograms']}
                                        />
                                    </div>

                                    <div className="col-xs-12">
                                        <p>
                                            <span className="red">NOTE:</span> Capacity / Divisions
                                            = Maximum Scale Graduations
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <button type="submit">Calculate</button>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <button
                                            className="reset-btn"
                                            type="button"
                                            onClick={ResetForm}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )
                    }}
                </Formik>

                <table id="tolerances-table" className="result-table responsive-table">
                    <tbody>
                        <tr>
                            <th>Division Range</th>
                            <th>Range of Test Weights</th>
                            <th>Maintenance Tolerance</th>
                            <th>Acceptance Tolerance</th>
                        </tr>
                        {scaleMax === 0 && (
                            <tr>
                                <td data-th="Division Range">Not Calculated</td>
                                <td data-th="Range of Test Weights">Not Calculated</td>
                                <td data-th="Maintenance Tolerance">Not Calculated</td>
                                <td data-th="Acceptance Tolerance">Not Calculated</td>
                            </tr>
                        )}
                        {GetTableRow(0, 500, 1)}
                        {GetTableRow(501, 2000, 2)}
                        {GetTableRow(2001, 4000, 3)}
                        {GetTableRow(4001, scaleMax, 5)}
                    </tbody>
                </table>
            </div>
        </Layout>
    )
}

export default ClassIIITolerance

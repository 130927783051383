import React, { useState } from 'react'
import { Formik } from 'formik'

import Layout from '../../components/Layout'
import { MicrovoltsValidation } from '../../components/Forms/Validation'
import { ScaleToolsNumberInput } from '../../components/Forms/ScaleToolsTextInput'

const uVGraduation = () => {
    const [mvv, setMvv] = useState('')
    const [excitation, setExcitation] = useState('')
    const [countBy, setCountBy] = useState('')
    const [totalLoadCell, setTotalLoadCell] = useState('')
    const [graduations, setGraduations] = useState('')

    const HandleSubmit = values => {
        const {
            capacity,
            loadCells,
            excitation: newExcitation,
            mvLoadCells,
            countBy: newCountBy
        } = values

        const newTotalLoadCell = loadCells * capacity

        setMvv(mvLoadCells)
        setTotalLoadCell(newTotalLoadCell)
        setCountBy(newCountBy)
        setExcitation(newExcitation)

        const numerator = mvLoadCells * newExcitation * 1000 * newCountBy
        const denomenator = newTotalLoadCell

        if (denomenator === 0) {
            setGraduations('NA')
        } else {
            setGraduations(numerator / denomenator)
        }
    }

    return (
        <Layout>
            <div id="page-main" className="section-wrapper page-content">
                <h1>Determining Microvolts Per Graduation</h1>
                <p>Determine the appropriate µV per graduation for your scale application.</p>

                <Formik
                    onSubmit={HandleSubmit}
                    initialValues={{
                        capacity: 0,
                        loadCells: 0,
                        excitation: 0,
                        mvLoadCells: 0,
                        countBy: 0
                    }}
                    validationSchema={MicrovoltsValidation}
                >
                    {({ handleSubmit, resetForm }) => {
                        const ResetForm = () => {
                            resetForm()
                            setMvv('')
                            setExcitation('')
                            setCountBy('')
                            setTotalLoadCell('')
                            setGraduations('')
                        }

                        return (
                            <form id="graduation" onSubmit={handleSubmit}>
                                <h2>Total Load Cell Build:</h2>
                                <div className="col-section col-no-margin">
                                    <div className="col-xs-12 col-sm-6">
                                        <ScaleToolsNumberInput
                                            label="Load Cell Capacity:"
                                            name="capacity"
                                        />
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <ScaleToolsNumberInput
                                            label="Number of Load Cells:"
                                            name="loadCells"
                                        />
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <ScaleToolsNumberInput
                                            label="Indicator Excitation:"
                                            name="excitation"
                                        />
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <ScaleToolsNumberInput
                                            label="mV/V of Load Cells:"
                                            name="mvLoadCells"
                                        />
                                    </div>
                                </div>

                                <h2>Formula for Count By:</h2>
                                <p>Count By = Scale Capacity / Graduations</p>
                                <div className="col-section col-no-margin">
                                    <div className="col-xs-12">
                                        <ScaleToolsNumberInput label="Count By:" name="countBy" />
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <button type="submit">Calculate</button>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <button
                                            className="reset-btn"
                                            type="button"
                                            onClick={ResetForm}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>

                                <div id="graduation-result">
                                    <h2>Formula:</h2>

                                    <div className="col-section col-no-margin">
                                        <div id="graduation-formula" className="col-xs-12 col-sm-7">
                                            <p>
                                                {mvv || null} mV/V * {excitation} Excitation * 1000
                                                * {countBy} Count By(lb/kg)
                                            </p>
                                            <p>{totalLoadCell}(Total Load Cell Build)</p>
                                        </div>
                                        <div id="graduation-total" className="col-xs-12 col-sm-5">
                                            <h3>
                                                <span>=</span> {graduations} µV/graduation
                                            </h3>
                                        </div>
                                    </div>
                                    <p className="small">
                                        This value should range from 1µV/graduation (or slightly
                                        less) to 30µV per graduation or more. Note: Always consult
                                        your digital weight indicator and quote specifications for
                                        the appropriate uV/graduation compatible with your device
                                    </p>
                                </div>
                            </form>
                        )
                    }}
                </Formik>
            </div>
        </Layout>
    )
}

export default uVGraduation
